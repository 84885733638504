.icon {
  color: #ccc;
}

.buttonWrapper {
  margin-top: 10px;
  margin-bottom: 0;
}

.authContentWrapper {
  -webkit-box-shadow: 0px 0px 30px 8px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 30px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 30px 8px rgba(0, 0, 0, 0.15);
  padding: 30px;
  border-radius: 15px;
  background: white;
}

.authFullScreen {
  height: calc(100vh - 63px);
}

.instructions {
  margin-top: 0px;
  max-width: 300px;
}

.smsInstructions {
  max-width: 300px;
  margin-bottom: 0 !important;
}

.resetLoginIcon {
  vertical-align: -6px;
}

.forgotPasswordLink {
  text-align: center;
}
